/* HTML: <div class="loader"></div> */
.main-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.container {
  position: absolute;
  top: 45%;
  left: 45%;
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 400px;
}
.ploader {
    width: 100px;
    aspect-ratio: 1;
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    background: #fff;
    filter: blur(5px) contrast(100) hue-rotate(300deg);
    mix-blend-mode: darken;
  }
  .ploader:before,
  .ploader:after{ 
    content: "";
    grid-area: 1/1; 
    width: 40px;
    height: 40px;
    background: #B643CD;
    animation: l7 3s infinite;
  }
  .ploader:after{ 
    animation-delay: -1.5s;
  }
  @keyframes l7{
    0%   {transform: translate(   0,0)}
    25%  {transform: translate(100%,0)}
    50%  {transform: translate(100%,100%)}
    75%  {transform: translate(   0,100%)}
    100% {transform: translate(   0,0)}
  }